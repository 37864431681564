import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function StickyFeetPage() {
  return (
    <TicketshopPage
      scriptString={`<script data-name-filter="sticky" src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="f8e48caf-038f-4062-b6b5-e963e15a7183" data-fixr-theme="light"></script>`}
      imageSrc="/logo-sticky-feet.png"
      backgroundSrc="/sticky-feet-hero.jpg"
      imageMaxWidth="562px"
      title="Sticky Feet"
      description={`A weekly stripped back DNB event bringing non stop rollers & deep basslines with low lighting, mad atmospheres and guest artists. Set in one of Leeds’ best intimate basement venues, the SF residents will be set up on stage, speakers facing front, lights dimmed, serving out serious NRG until the early hours.`}
    />
  );
}
